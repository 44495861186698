<template>
  <div id="orderDate">
    <div class="dateWrap">
      <div :class="actTemp == index? 'dateItem act': 'dateItem'" v-for="(item, index) in ordersCount" :key="index" @click="goSubPage(item.name)">
        <p class="dateNum">
          {{ item.num }}
        </p>
        <p class="dateText">
          {{ item.title }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    ordersCount: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      actTemp: 2,
    };
  },
  created() {},
  methods: {},
};
</script>
<style lang="less">
#orderDate {
  padding: 0 0 1rem 0;
  background: #fff;
  width: 95%;
  margin: 0 auto;
  border-radius: 10px;
  // border-top-left-radius: 10px;
  // border-top-right-radius: 10px;
  .dateWrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    box-sizing: border-box;
    margin-top: 1rem;

    .dateNum {
      font-size: 2rem;
      font-weight: 600;
      // color: #fff;
    }
    .dateText {
      font-size: 1.4rem;
      font-weight: 400;
    }
    .dateItem {
      // border-right: 1px solid #FAFAFA;
      // border-bottom: 1px solid #FAFAFA;
      line-height: 2.8rem;
      box-sizing: border-box;
      font-size: 1.5rem;
      padding: 1rem 0.5rem;
      width: 25%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #000c;
      white-space: nowrap;
      font-weight: 600;
      &.act {
        // border-bottom: 4px solid #fff;
        color: #ff7963 !important;
        font-size: 1.7rem;
      }
    }
  }
}
</style>>