<template>
  <div id="calendar">
    <!-- <p>待上门服务日程</p> -->
    <!-- <div class="calendarWrap">
      <div class="lastDay"></div>
      <div class="nowCalendar">
        <div class="dataTime" v-for="(item, index) in 7" :key="index">
          <p></p>
          <p></p>
        </div>
      </div>
      <div class="nextDay"></div>
    </div> -->
    
    <van-calendar
      title="待办日程"
      :poppable="false"
      :show-confirm="false"
      :style="{ height: '28rem' }"
      color="#FF816C"
      @confirm="confirm"
      row-height="40"
      :show-subtitle= "false"
    />
  </div>
</template>
<script>
export default {
  data() {
    return {
      dataList: [""],
      date: "",
      show: false,
    };
  },
  methods: {
    confirm(res) {
      console.log(res)
      this.$emit("nowDay", res)
    }
  },
};
</script>>
<style lang="less">
#calendar {
  width: 94%;
  height: 10rem;
  background: #fff;
  box-shadow: 10px;
  margin: 0 auto;
  left: calc(50% - 47%);
  top: 21.7rem;
  box-shadow: 0 5px 10px 0 #f2f2f2;
  border-radius: 10px;
  p {
    text-align: center;
    font-size: 1.4rem;
    margin: 0.5rem 0;
  }
  .van-calendar__header-title {
    border-bottom: 1px solid #f2f2f2;
  }
  .calendarWrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 5rem;
    .lastDay,
    .nextDay {
      width: 6%;
      height: 5rem;
    }
    .nowCalendar {
      flex: 1;
      height: 5rem;
      display: flex;
      .dataTime {
        flex-wrap: nowrap;
        width: calc(100% / 7);
        background: #f2f2f2;
        height: 5rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
</style>>