<template>
  <div id="orders" v-if="page == 1">
    <van-nav-bar title="订单" left-arrow :border="false" @click-right="searchOrder" @click-left="goSubPage('home')">
      <template #right>
        <van-icon name="search" size="18" />
      </template>
    </van-nav-bar>
    <BottomNav />
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <OrderDate style="position: relative" :ordersCount="vanTagList" />
      <div class="schedule">
        <p class="today"><span>待上门订单</span> <span> {{selectDay.date_at}}</span> </p>
        <div class="schedule_weekday schedule_public">
          <span v-for="(item, index) in 7" :key="index" class="flexCenter">
            {{ week_cn[moment().add(index, 'day').format('d')] }}
          </span>
        </div>
        <div class="schedule_day schedule_public">
          <div class="day_item flexCenter" v-for="(item,index) in schedule_list" :key="index" :class="selectDay == item? 'select':''" @click="selectScheduleDay(item)">
            {{item.text}}
            <div class="value_item flexCenter" v-show="item.value">
              {{item.value}}
            </div>
          </div>
          <div class="next" v-show="false">
            <van-icon name="arrow" />
          </div>
        </div>
      </div>
      <div>
        <van-empty class="custom-image" image="https://img.yzcdn.cn/vant/custom-empty-image.png" description="暂无待上门订单" v-if="JSON.stringify(dayOrderlist) == '[]' " />
        <div class="clock-in-list" v-for="(item,index) in dayOrderlist" :key="index" v-else @click="goSubPage('sign-in-detail',item.order_id)">
          <div class="left-image" :style="`background: url('${cdnHttp}${item.commoditys[0].product_images}') no-repeat center / cover`" @click.stop="imagePreview(item.commoditys[0].product_images)"></div>
          <div class="center-content">
            <p :style="{color: '#000', fontWeight: '600'}">{{moment(item.visit_time).hour()}}:00 - {{moment(item.visit_time).add(2,'h').hour()}}:00</p>
            <P>{{item.client_name}} <span :style="{color:'#02abd6', marginLeft:'1rem'}">
                <a :href="`tel: ${item.client_mobile}`" :style="{color:'#02abd6'}">
                  <van-icon name="phone" />
                  联系他
                </a>
              </span></P>
            <p class="line-over-hidden">{{item.full_address}}</p>
          </div>
          <div class="right-click flexCenter">
            <van-icon name="arrow" />
          </div>
        </div>
      </div>
    </van-pull-refresh>
    <Popup />
    <loading :show="clolk_in_loading" title="日程加载中..." />
  </div>
  <div id="search" v-else-if="page == 2">
    <top-nav-bar theme="white" @clickLeft="clickLeft" @clickRight="clickRight">
      <template #left>
        <van-icon name="arrow-left" />
      </template>
      <template #center>
        <input type="text" class="order-serach" placeholder="可模糊搜索" v-model="serchCondition">
      </template>
      <template #right>
        搜索
      </template>
    </top-nav-bar>

    <p :style="{padding: '60px 0 10px 0', textAlign:'center', fontSize: '1.4rem'}">
      <span v-if="JSON.stringify(searchOrderList) !== '[]'">已为您查询到{{searchOrderList.length}}条数据 </span>
      <span v-else>暂无数据 </span>

    </p>
    <OrderListPublic v-for="(item,i) in searchOrderList" :key="i" :listPublicObj="item" :toPageId="item.id" toPageName="success_detail" />
    <loading :show="loadingShow" title="搜索中..." />
  </div>
</template>
<script>
import BottomNav from "@/components/NavBar/BottomNav.vue";
import TopNavBar from "@/components/public/TopNavBar.vue";
import OrderDate from "@/components/order/OrderDate.vue";
import Calendar from "@/components/order/Calendar.vue";
// import CalendarWeek from "@/components/order/CalendarWeek.vue";
import OfferMaster from "@/components/home/OfferMaster.vue";
import OrderListPublic from "@/components/order/OrderListPublic.vue";
import Popup from "@/components/effect/Popup.vue";
import Schedule from "@/components/order/Schedule.vue";
import moment from "moment";
import { ImagePreview } from "vant";

export default {
  data() {
    return {
      serchCondition: "",
      // 搜索出来的订单列表
      searchOrderList: [],
      // 搜索某天的待上门订单列表
      dayOrderlist: [],
      loadingShow: false,
      clolk_in_loading: false,
      page: 1,
      selectDay: {},
      week_cn: ["日", "一", "二", "三", "四", "五", "六"],
      schedule_list: [],
      moment,
      count: 0,
      isLoading: false,
      orderDayTime: "",
      calendarOrder: [],
      ordersCount: {},
      vanTagList: [
        {
          num: 0,
          title: "待预约",
          name: "appoint",
        },
        {
          num: 0,
          title: "待提货",
          name: "",
        },
        {
          num: 0,
          title: "待上门",
          name: "sign-in",
        },
        {
          num: 0,
          title: "待完成",
          name: "finish_list",
        },
        {
          num: 0,
          title: "待收款",
          name: "payment_list",
        },
        {
          num: 0,
          title: "交易成功",
          name: "success_list",
        },
        {
          num: 0,
          title: "交易关闭",
          name: "close_list",
        },
        {
          num: 0,
          title: "遗留订单",
          name: "",
        },
      ],
    };
  },
  components: {
    BottomNav,
    TopNavBar,
    OrderDate,
    Calendar,
    OfferMaster,
    Popup,
    Schedule,
    OrderListPublic,
  },
  created() {
    console.log(this.$root.moment);
    // 获取订单统计列表
    this.getOrderDate();
    this.getSchedule();
  },
  methods: {
    /** 下拉刷新
     *
     */
    onRefresh() {
      setTimeout(() => {
        this.isLoading = false;

        this.getOrderDate();

        this.$toast("刷新成功");
      }, 1000);
    },
    /** 获取订单分类数量总数据
     *
     */
    getOrderDate() {
      this.$http.get("/api/v2/worker/orders/count").then((res) => {
        console.log("订单统计列表", res);
        let _orderCountArr = [
          "pending_appoint",
          "pending_delivery",
          "pending_to_home",
          "pending_completed",
          "panding_payment",
          "tansaction_complete",
          "trading_closed",
          "legacy_order",
        ];
        _orderCountArr.forEach((k, index) => {
          this.vanTagList[index].num = res.data.result[k];
        });
        this.getSchedule();
        console.log(this.vanTagList);
      });
    },
    /** 获取日程展示数据
     *
     */
    getSchedule() {
      this.$http
        .get("api/v2/worker/orders/waiting_to_home/count")
        .then((res) => {
          console.log("日程", res);
          this.schedule_list = res.data.count;
          this.selectDay = this.schedule_list[0];
          this.getClockIn();
        });
    },
    /** 选择日程日期
     *
     */
    selectScheduleDay(item) {
      console.log(item);
      this.selectDay = item;
      this.getClockIn();
    },
    /** 待上门列表获取
     *
     */
    getClockIn() {
      this.clolk_in_loading = true;
      this.$http
        .get(
          `/api/v2/worker/orders/clock_in?visit_time=${this.selectDay.date_at}`
        )
        .then((res) => {
          console.log("日程当天订单列表", res);
          this.dayOrderlist = res.data.clock_ins;
          this.clolk_in_loading = false;
        });
    },
    /** 打开搜索
     *
     */
    searchOrder() {
      this.page = 2;
    },
    /** 关闭搜索
     *
     */
    clickLeft() {
      this.page = 1;
    },
    /** 订单模糊搜索
     *
     */
    clickRight() {
      this.loadingShow = true;
      this.$http
        .post("/api/v2/worker/orders", { keywords: this.serchCondition })
        .then((res) => {
          console.log("订单模糊搜索", res);
          this.searchOrderList = res.data.orders.data;
          this.loadingShow = false;
        });
    },
    imagePreview(imgList) {
      let _imgList = [];
      _imgList = imgList.split(",").map((option) => this.cdnHttp + option);
      ImagePreview({ images: _imgList, showIndex: false, closeable: true });
    },
  },
};
</script>
<style lang="less">
#orders {
  padding-bottom: 4rem;
  .custom-image {
    .van-empty__image {
      width: 90px;
      height: 90px;
    }
  }
  .topTitle {
    font-size: 1.8rem;
    font-weight: 400;
  }
  .dataWrap {
    margin-top: 1rem;
    width: 100%;
    box-sizing: border-box;
    background: #fff;
    padding: 1rem;
    .baseData {
      .baseDataType {
        border-left: 5px solid #ff816c;
        font-size: 1.8rem;
        font-weight: 600;
        p {
          padding-left: 1.2rem;
        }
      }
      .evaluation {
        font-size: 1.4rem;
        color: #b6b6b6;
        padding: 1rem 2rem 0;
      }
      .dataContent {
        .dataList {
          font-size: 1.5rem;
          padding: 0.4rem 0;
          img {
            margin-right: 0.6rem;
          }
        }
      }
    }
  }
  .schedule {
    min-height: 10rem;
    background: #fff;
    margin: 0.5rem 1rem;
    transform: translateY(0.5rem);
    border-radius: 10px;
    padding: 0.5rem 0 3.2rem 0;
    overflow: hidden;
    .today {
      padding: 0.8rem 0;
      border-bottom: 1px solid #f2f2f2;
      text-align: center;
      font-size: @theme-font-size-m;
      background: #ff7963cc;
      color: #fff;
      transform: translateY(-5px);
      display: flex;
      justify-content: space-between;
      padding: 1rem 2rem;
    }
    .schedule_public {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    .schedule_weekday {
      padding: 1rem 3rem 0 3rem;
      font-size: @theme-font-size-m;
      color: #999999;
    }
    .schedule_day {
      padding: 0.5rem 3rem;
      box-sizing: border-box;
      position: relative;
      .next {
        position: absolute;
        right: 1rem;
        top: -0.7rem;
      }
      .day_item {
        width: 3.5rem;
        height: 3.5rem;
        border-radius: 100rem;
        font-size: @theme-font-size-l;
        position: relative;
        &.select {
          color: #ff7963;
          background: #ff796322;
        }
        .value_item {
          width: 3rem;
          height: 1.8rem;
          background: #ff7c01;
          border-radius: 50rem;
          color: #fff;
          position: absolute;
          top: 4rem;
          font-size: @theme-font-size-m;
        }
      }
    }
  }
  .clock-in-list {
    min-height: 8rem;
    background: #fff;
    margin: 1rem 1rem;
    transform: translateY(1rem);
    border-radius: 10px;
    box-shadow: -2px 0px 8px 0 #0001;
    padding: 1rem;
    display: flex;
    align-items: center;
    .left-image {
      width: 8rem;
      height: 8rem;
      background: sienna;
      border-radius: 10px;
    }
    .center-content {
      // height: 8rem;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 1rem 0 1.5rem;
      p {
        // padding: 0.4rem 0;
        line-height: 2.5rem;
        font-size: @theme-font-size-m;
        color: @themeFontColor;
        &.line-over-hidden {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
      }
    }
    .right-click {
      width: 2rem;
    }
  }
}
#search {
  .order-serach {
    width: 100%;
    height: 70%;
    border: 1px solid @themeBacColor;
    background: @themeBacColor;
    border-radius: 50rem;
    color: @themeFontColor;
    text-indent: 1rem;
  }
  input::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    font-size: @theme-font-size-m;
    color: #bbbbbb;
    font-weight: 400;
  }
}
</style>