<template>
  <div id="popup">
    <div class="bacWrap">

    </div>
  </div>
</template>
<script>
export default {};
</script>>
<style less="less">
#popup {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  /* background: #0006; */
}
</style>>