<template>
  <!-- 师傅日程 -->
  <div id="schedule">
    <p>11月5日待上门服务</p>
    <div>

    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less">
#schedule {
}
</style>
