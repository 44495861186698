<template>
  <div id="offerMaster">
      <div class="offer">
        <span class="offerTitle">待报价</span>
        <span class="offerNum">{{MasterListNum}}</span>
      </div>
    <div class="offerInfo">
      <div class="sort flex-public">
        <slot name="sortLeft"></slot>
        <slot name="sortRight"></slot>
        <!-- <svg
          t="1600842715692"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="10393"
          width="20"
          height="20"
        >
          <path
            d="M655.0016 844.3392a38.0416 38.0416 0 0 1-38.7072-38.7072V218.5728a38.7072 38.7072 0 0 1 77.4144 0v587.0592a39.68 39.68 0 0 1-38.7072 38.7072z"
            p-id="10394"
            fill="#bfbfbf"
          />
          <path
            d="M834.56 436.6336a37.12 37.12 0 0 1-27.2896-11.6736l-179.2-179.2a38.2976 38.2976 0 0 1 54.1696-54.1696l179.2 179.2a37.4272 37.4272 0 0 1 0 54.1696 39.3216 39.3216 0 0 1-26.88 11.6736zM364.6976 844.3392a38.0416 38.0416 0 0 1-38.7072-38.7072V218.5728a38.7072 38.7072 0 1 1 77.4144 0v587.0592a39.68 39.68 0 0 1-38.7072 38.7072z"
            p-id="10395"
            fill="#bfbfbf"
          />
          <path
            d="M364.6976 844.3392a37.12 37.12 0 0 1-27.0848-11.6224l-179.2-179.2A38.2976 38.2976 0 1 1 212.4288 599.04l179.2 179.2a38.7072 38.7072 0 0 1-27.0848 65.792z"
            p-id="10396"
            fill="#bfbfbf"
          />
        </svg>
        <span class="public-margin">综合</span> -->
      </div>
      <div class="recording flex-public">
        <van-tag type="primary" size="large" color="#FF816C" @click="goSubPage('quoted')">已报价<svg t="1602746112180" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1138" width="16" height="16">
            <path d="M71.493818 363.589818L449.861818 809.867636a81.454545 81.454545 0 0 0 124.276364 0l378.368-446.277818A51.2 51.2 0 0 0 913.454545 279.272727H110.545455a51.2 51.2 0 0 0-39.051637 84.317091z" p-id="1139" fill="#ffffff"></path>
          </svg></van-tag>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    MasterListNum: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      value: 0,
      switch1: false,
      switch2: false,
      option: [
        { text: "全部商品", value: 0 },
        { text: "新款商品", value: 1 },
        { text: "活动商品", value: 2 },
      ],
    };
  },
  methods: {
    toOrderDetails() {
      this.$router.push(`/orderDetails`);
    },
    goQuotedHistory() {
      this.$router.push(`/quotedHistory`);
    },
  },
};
</script>
<style lang="less">
#offerMaster {
  width: 95%;
  margin: 0 auto;
  font-size: 1.4rem;
  display: flex;
  justify-content: space-between;
  .offer {
    position: relative;
    .offerTitle {
      font-size: 1.8rem;
      font-weight: 700;
      letter-spacing: 1px;
      // padding-bottom: 5px;
      // border-bottom: 2px solid #000;
    }
    .offerNum {
      font-size: 1.2rem;
      position: absolute;
      top: 0;
      padding-left: 5px;
      color: #666;
    }
  }
  .offerInfo {
    display: flex;
    .flex-public {
      margin-left: 1rem;
      padding: 0 0.1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      .public-margin {
        margin-left: 0.5rem;
      }
    }
  }
}
</style>